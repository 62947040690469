import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//import scss
import "assets/css/pages/section4.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
    title: 'some title',
    description: 'description stuff',
    canonical: 'https://page',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'military-friendly, realtor,other tags'
        }
    }
}

//goes in return or component
<DocumentMeta {...meta} />

*/

export default class Section4 extends Component {
  constructor() {
    //do nothing
    super();
    console.log("loaded");
  }
  componentDidMount() {
    console.log("test");
  }
  render() {
    return (
      <div className={classNames("section", "the-process")}>
        <div className={classNames("row", "process-header")}>
          <div className="process-village-image mobile-only">
            <img
              alt="Neighborhood outline"
              src={require("assets/img/test-images/icons/Neighborhood_outline.svg")}
            />
          </div>
          <h2>Our Proven Process</h2>
          <div
            className={classNames("process-line-dashed", "process-line-horz")}
          >
            <div></div>
          </div>
        </div>
        <div className="row">
          <div className={classNames("col-12", "process-col")}>
            <GridContainer
              spacing={2}
              columns={{ xs: 12, sm: 12, md: 12 }}
              className="process-grid-cont"
            >
              <GridItem
                xs={12}
                sm={12}
                md={4}
                key={0}
                className={classNames("process-item-wrapper")}
              >
                <div className="process-item">
                  <div className="process-num">
                    <span>
                      <i class="fa-solid fa-phone-volume"></i>
                    </span>
                    <div
                      className={classNames(
                        "process-line-dashed",
                        "process-line-horz"
                      )}
                    >
                      <div></div>
                    </div>
                  </div>
                  <div className="process-text">
                    <h2>
                      <span>1</span>24 Minute Meeting
                    </h2>
                    <div>
                      <ul>
                        <li>About Us</li>
                        <li>About You</li>
                        <li>Discuss Your Home Goals</li>
                        <li>Next Steps</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={4}
                key={1}
                className={classNames("process-item-wrapper")}
              >
                <div className="process-item">
                  <div className="process-num">
                    <span>
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </span>
                    <div
                      className={classNames(
                        "process-line-dashed",
                        "process-line-horz"
                      )}
                    >
                      <div></div>
                    </div>
                  </div>
                  <div className="process-text">
                    <h2>
                      <span>2</span>Where's Waldo?
                    </h2>
                    <div>
                      <ul>
                        <li>
                          <i>The WeVett Team will...</i>
                        </li>
                        <li>Research Real Estate Agents</li>
                        <li>Interview Qualified Agents</li>
                        <li>Recommend the Best Fit</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                key={2}
                className={classNames(
                  "process-item-wrapper-corner",
                  "process-item-wrapper"
                )}
              >
                <div
                  className={classNames(
                    "process-item-corner",
                    "process-item",
                    "process-vertical"
                  )}
                >
                  <div
                    className={classNames(
                      "process-num",
                      "process-icon-vertical"
                    )}
                  >
                    <span>
                      <i class="fa-regular fa-handshake"></i>
                    </span>
                    <div
                      className={classNames(
                        "process-line-dashed",
                        "process-line-vertical"
                      )}
                    >
                      <div></div>
                    </div>
                  </div>

                  <div className="process-text">
                    <h2>
                      <span>3</span>First Date
                    </h2>
                    <div>
                      <ul>
                        <li>Review the Agent's Profile</li>
                        <li>Get Connected with Your Agent</li>
                        <li>Provide Feedback</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={8}
                key={3}
                className={classNames(
                  "process-item-wrapper",
                  "process-row-2",
                  "process-bottom-line",
                  "process-cta"
                )}
              >
                <div>
                  <span>
                    Only three steps for a successful PCS
                    <br className="mobile-only" /> - call{" "}
                    <a href="tel:13166695273">316-669-5273</a> to get started!
                  </span>
                </div>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                key={4}
                className={classNames("process-item-wrapper", "process-row-2")}
              >
                <div className={classNames("process-item", "process-vertical")}>
                  <div
                    className={classNames(
                      "process-num",
                      "process-icon-vertical"
                    )}
                  >
                    <span>
                      <i class="fa-regular fa-headset"></i>
                    </span>
                  </div>
                  <div
                    className={classNames("process-text", "number-alignment")}
                  >
                    <h2>
                      <span>+1</span>On Standby
                    </h2>
                    <div>
                      <ul>
                        <li>
                          <i>The WeVett Team will...</i>
                        </li>
                        <li>Check-in on Your Transaction</li>
                        <li>Answer Any Questions</li>
                        <li>Provide PCS Decision Support</li>
                        <li>20-Year Client Vision</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </GridItem>
              <div className="process-image-left process-village-image">
                <img
                  alt="Neighborhood outline"
                  src={require("assets/img/test-images/icons/Neighborhood_outline.svg")}
                />
              </div>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}
