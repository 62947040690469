import React, { Component } from "react";
import Section6 from "views/test/section6.js";

class Section1 extends Component {
  render() {
    return (
      <div className="blog-post">
        <div className="section hero">
          <div className="section-header boxed">
            <h1 className="title">
              WeVett Realty's Top Real Estate Agents of 2024
            </h1>
            <img
              src={require("assets/img/agents/top_agents/TopAgents2024.gif")}
              alt="WeVett 2024 Top Agents"
            ></img>
          </div>
          <div className="boxed"></div>
        </div>
        <div className="section">
          <div className="boxed">
            <h2>The Elite of Military-Friendly Real Estate Agents</h2>
            <p>
              The results are in, and we are thrilled to announce the top real
              estate agents of WeVett Realty for 2024! This past year, these
              outstanding agents went above and beyond, showcasing their
              expertise, dedication, and passion for helping military families
              achieve the dream of homeownership. Without further ado, let’s
              give a big congratulations to our top three agents of 2024: Josh
              Dukes (Virginia), Dan Kenney (Colorado), and Rhonda Chambal
              (Ohio)!
            </p>
            <div className="inline-image-container flex">
              <div className="img-wrapper">
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=762&v=0"
                  target="_blank"
                >
                  <img
                    src={require("assets/img/agents/top_agents/JoshDukes-square.jpg")}
                    alt="Josh Dukes - Real Estate Agent"
                  ></img>
                </a>
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=762&v=0"
                  target="_blank"
                >
                  Meet Josh
                </a>
              </div>
              <div className="img-wrapper">
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=1427&v=0"
                  target="_blank"
                >
                  <img
                    src={require("assets/img/agents/top_agents/DanKenney.jpg")}
                    alt="Dan Kenney - Real Estate Agent"
                  ></img>
                </a>
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=1427&v=0"
                  target="_blank"
                >
                  Meet Dan
                </a>
              </div>
              <div className="img-wrapper">
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=1317&v=0"
                  target="_blank"
                >
                  <img
                    src={require("assets/img/agents/top_agents/RhondaChambal.jpg")}
                    alt="Rhonda Chambal - Real Estate Agent"
                  ></img>
                </a>
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=1317&v=0"
                  target="_blank"
                >
                  Meet Rhonda
                </a>
              </div>
            </div>
            <div>
              <p>
                What sets Josh, Dan, and Rhonda apart? They’ve proven to be the
                ultimate experts in navigating the unique challenges military
                families face when relocating. From understanding the
                intricacies of PCS moves to offering personalized advice on
                finding the perfect home, they truly get the military PCS
                lifestyle. Their ability to make homeownership a reality for
                military families — no matter the challenges — is why they stand
                at the top of the pack.
              </p>
              <p>
                These top agents were chosen based on their impressive sales
                volume, exceptional client satisfaction, closing ratios, and
                responsiveness. They’ve consistently demonstrated their ability
                to exceed expectations in these key areas, earning the trust of
                their clients and the respect of their peers.
              </p>
              <p>
                Their commitment, passion, and unrivaled work ethic have earned
                them the respect and trust of our military families. These top
                agents have truly set the standard for what it means to provide
                exceptional service in real estate.
              </p>
            </div>
          </div>
          <div className="boxed">
            <h2>Honorable Mention: Derek Walden, Our Rising Star</h2>
            <div className="inline-image-container flex">
              <div className="img-wrapper">
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=20&v=0"
                  target="_blank"
                >
                  <img
                    src={require("assets/img/agents/top_agents/DerekWalden.jpg")}
                    alt="Derek Walden - Real Estate Agent"
                  ></img>
                </a>
                <a
                  href="https://wevettrealty.com/profiles/agents/?agid=20&v=0"
                  target="_blank"
                >
                  Meet Derek
                </a>
              </div>
            </div>
            <div>
              <p>
                We also want to give a special shoutout to an up-and-comer who
                has been making waves in the industry — Derek Walden, our
                honorable mention. Derek joined our team recently and has
                already made a significant impact as a go-to agent in our
                backyard of Wichita, Kansas. He’s risen to the challenge and
                proven himself to be an asset to WeVett Realty and the military
                families we serve. We can't wait to see where his journey takes
                him next!
              </p>
            </div>
          </div>
          <div className="boxed">
            <h2>A Heartfelt Thank You to All Our WeVett Agents</h2>
            <div>
              <p>
                While we’re spotlighting Josh, Dan, Rhonda, and Derek today,
                it’s important to recognize that we are fortunate to work with
                hundreds of talented WeVett agent partners just like them. Every
                day, these incredible agents stand ready to help military
                families across the country find their next home. Their
                commitment to excellence and unwavering support for the military
                community make them invaluable members of the WeVett Realty
                family.
              </p>
              <p>
                We are proud of all our agents, and we can’t wait to match you
                with the perfect partner for your next PCS journey. Whether
                you’re moving across the country or just down the road, our team
                is always ready to help military families find their perfect
                home and make the PCS process as smooth as possible.
              </p>
            </div>
          </div>
          <div className="boxed">
            <h2>Here's to Another Successful Year Ahead!</h2>
            <div>
              <p>
                To our top agents of 2024 — congratulations once again! And to
                all our partners, thank you for your continued dedication. We
                can’t wait to see what 2025 brings!
              </p>
              <p>
                Ready to make your next PCS move? Reach out to us to get matched
                with a military-specialized agent today, and let’s make your
                homeownership dreams come true!
              </p>
            </div>
          </div>
          <Section6></Section6>
        </div>
      </div>
    );
  }
}

export default Section1;
