/*eslint-disable*/
import React from "react";
//import { Route, Switch } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";
import CustomFooter from "components/CustomFooter/CustomFooter.js";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";

// sections for this page
//import Test from "views/test/test.js";
import MasonryGrid from "views/test/section1.js";
import CamoBorder from "views/test/camoBorder.js";
import Section2 from "views/test/section2.js";
import Section3 from "views/test/section3.js";
import Section4 from "views/test/section4.js";
import Section5 from "views/test/section5.js";
import Section6 from "views/test/section6.js"; //Ready to connect??????

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import "assets/css/pages/home.scss";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    addClickEvents();
  });
  const classes = useStyles();
  return (
    <div className="pageDiv">
      <Header
        brand="WeVett Realty"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 80,
          color: "secondary",
        }}
      />
      <div
        className={classNames(classes.main, classes.mainRaised, "main-content")}
      >
        <MasonryGrid />
        <CamoBorder />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
      </div>
      <CustomFooter />
    </div>
  );
}
