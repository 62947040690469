//TOP AGENTS PAGE

//include site header and footer
//should be a blog post url format
import React from "react";
//import { Route, Switch } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";
import CustomFooter from "components/CustomFooter/CustomFooter.js";

//update stylesheet as needed
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import "assets/css/pages/home.scss";
import "assets/css/pages/TopAgentsPage.scss";

import Section1 from "views/TopAgentsPage/Section1.jsx";

const useStyles = makeStyles(presentationStyle);
export default function TopAgentsPage() {
  /* React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    addClickEvents();
  }); */
  const classes = useStyles();
  return (
    <div className="pageDiv">
      <Header
        brand="WeVett Realty"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 80,
          color: "secondary",
        }}
      />
      <div
        className={classNames(
          classes.main,
          classes.mainRaised,
          "main-content",
          "top-agents-page"
        )}
      >
        <Section1 />
      </div>
      <CustomFooter />
    </div>
  );
}
